/* ServiceView.css */
.service-view {
    position: relative;
    width: 100%;
    height: 75vh; /* Make the image cover the full screen */
    overflow: hidden; /* Ensure the image doesn't overflow */
  }
  
  .service-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Make sure the image covers the entire container */
    filter: brightness(80%);
  }
  
  .service-info {
    position: absolute;
    top: 50%; /* Center the title vertically */
    left: 50%; /* Center the title horizontally */
    transform: translate(-50%, -50%); /* Center the title perfectly */
    text-align: center;
    color: white; /* Ensure the title is visible on top of the image */
  }
  
  .service-title {
    font-size: 36px;
  }
  
  .service-description {
    margin-top: 20px; /* Add margin from the top of the image */
    text-align:justify;
  }
  
  .service-content {
    margin-top: 20px; /* Add margin from the top of the image */
    text-align: justify;
  }

  .content{
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10%;
    padding-right: 10%;
  }

  /* li{
    list-style:circle;
  } */
  