.heading {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #073F6E;
  font-size: 2.5rem;
  font-weight: 600;
  padding-bottom: 10px;
}
.main{
  padding: 10px;
}
.img {
  border-radius: 10px;
  transition: all 350ms ease;
  transition: border-radius 0.3s ease;
}
.img:hover{
  border-radius: 20px;
  filter: opacity(.8);
}
.model{
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out ;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  overflow: hidden;
  z-index: 999;
}

.model.open{
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}

.model img{
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 20px 0 20px;
  margin: 0 auto;
}

.model.open svg{
  position: fixed;
  top: 10px;
  right: 10px;
  width: 2rem;
  height: 2rem;
  padding: 5px;
  background-color: rgb(0, 0, 0, 0.4);
  color: white;
  cursor: pointer;
}