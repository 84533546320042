.main {
  display: flex;
  flex-direction: column;
  margin-left: 15%;
  margin-right: 15%;
  margin-top: 35px;
}

.heading {
  color: #073F6E;
  font-size: 1.5rem;
  font-weight: 600;
  padding-bottom: 10px;
}

.cards {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(300px, 1fr)
  );
  gap: 20px;
}

@media (max-width: 400px) {
  .main{
    margin-left: 3%;
    margin-right: 3%;
  }
}
