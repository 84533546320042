.job {
  padding-left: 8%;
  padding-right: 8%;
}
p {
  text-align: justify;
}
._li {
  list-style: circle;
}
.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}
.styled-table thead tr {
  background-color: #023e72;
  color: #ffffff;
  text-align: left;
}
.styled-table th,
.styled-table td {
  padding: 12px 15px;
}
.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #023e72;
}
.styled-table tbody tr.active-row {
  font-weight: bold;
  color: #023e72;
}
.bt {
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn {
  color: white;
  background-color: #023e72;
  padding: 10px;
}
.table{
    overflow-x: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 900px) {
  .table {
    display:block;
    justify-content:flex-start;
    align-items: center;
  }
}