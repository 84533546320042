.main {
  background-color: #07406ef3;
  width: 100%;
}

.para {
  margin-left: 15%;
  margin-right: 15%;
  padding-top: 10px;
  padding-bottom: 10px;
  color: black;
  text-align: justify;
}

.imp {
  text-decoration: underline;
  font-weight: bold;
}


@media (max-width: 400px) {
  .para{
    margin-left: 5%;
    margin-right: 5%;
  }
}