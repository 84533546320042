.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.heading {
  color: #073F6E;
  font-size: 2rem;
  text-align: center;
  margin-bottom: 20px;
}

.form {
  width: 100%;
  max-width: 400px;
}

.input {
  margin-bottom: 15px;
  padding: 10px;
}

.selector {
  display: flex;
  gap: 15px;
}
.select {
  margin-bottom: 15px;
  width: 100%;
  height: 45px;
}

.btn {
  padding: 10px 20px;
  background-color: transparent;
  border: solid 1px #073F6E;
  border-radius: 20px;
  color: #073F6E;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.btn:hover {
  background-color: #073F6E;
  color: white;
  border-color: white;
}

@media (max-width: 768px) {
  .container {
    padding: 10px;
  }
}
