.main {
  margin-left: 15%;
  margin-right: 15%;
}

.heading {
  color: black;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  font-size: 1.5rem;
  font-weight: bold;
  color: #073F6E;
}

.list {
  color: black;
  padding: 5px;
  text-align: justify;
}

.head {
  color: #073F6E;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: justify;
}

@media (max-width: 400px) {
  .main{
    margin-left: 5%;
    margin-right: 5%;
  }
}