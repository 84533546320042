* {
  margin: 0;
  padding: 0;
  text-decoration: none !important;
  list-style: none;
  box-sizing: border-box;
}

header {
  /* background: #fff; */
  /* background: transparent; */
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 0 50px;
  /* position: absolute; */
  position: fixed;
  z-index: 2;
  /* margin-top: 10px; */
}

.color{
  /* background: #073F6E; */
  backdrop-filter: blur(8px);
  box-shadow: 0 5px 15px -2px rgba(0, 0, 0, 0.2);
  /* transition: 0.5s; */
}

header .logo {
  font-size: 30px;
  text-transform: uppercase;
  cursor: pointer;
}

img {
  width: 7rem;
  /* height: 6rem; */
}

header nav ul {
  display: flex;
}
header nav ul li a {
  display: block;
  color: #000;
  font-size: 1rem;
  font-weight: 500;
  padding: 10px 25px;
  border-radius: 50px;
  text-transform: uppercase;
  transition: 0.2s;
  margin-right: 5px;
}
header nav ul li a:hover,
header nav ul li a.active {
  border-bottom-color: #073F6E;
  color: #fefefe;
  background-color: #073F6E;
}
.hamburger {
  cursor: pointer;
  display: none;
  z-index: 1000;
}
.hamburger div {
  width: 30px;
  height: 3px;
  margin: 5px 0;
  background: #000;
}
@media only screen and (max-width: 900px) {
  header {
    padding: 0 30px;
  }
}
@media only screen and (max-width: 700px) {
  .hamburger {
    display: block;
  }

  .hamburger .line {
    width: 30px;
    height: 3px;
    /* background: #fefefe; */
    margin: 6px 0;
  }

  header nav {
    position: absolute;
    left: 0;
    right: 0;
    /* top: 70px; */
    width: 100vw;
    background: #fff;
    transition: 0.3s;
    height: 0px;
    overflow: hidden;
  }
  header nav ul {
    display: block;
    width: fit-content;
    margin: 70px auto 0 auto;
    text-align: center;
    transition: 0.5s;
    opacity: 0;
  }
  header nav ul li a {
    margin-bottom: 12px;
  }

  nav ul li a.active {
    background-color: #5c8505;
  }

  nav.active {
    height: 350px;
  }
  nav.active ul {
    opacity: 1;
  }
}
