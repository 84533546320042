.main {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 25px;
}
.img {
  width: 100%;
  height: 220px;
  object-fit: cover;
}
.heading {
  font-size: 1.3rem;
  font-weight: 600;
  color: #073F6E;
}
.description {
  color: black;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  /* Specify the number of lines before truncating */
  -webkit-line-clamp: 4;
  text-align: justify;
}