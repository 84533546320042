.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
}
.heading {
  color: #073F6E;
  font-size: 2.8rem;
  text-align: center;
  font-weight: 400;
  margin-top: 130px;
}
.btn {
  display: flex;
  justify-content: center;
}
.button {
  padding: 15px;
  background-color: transparent;
  color: #073F6E;
  border-radius: 25px;
  border: none;
  border: solid 2px #073F6E;
}
.button:hover {
  color: white;
  background-color: #073F6E;
  cursor: pointer;
  border-color: white;
}
.description {
  color: black;
  padding-left: 15%;
  padding-right: 15%;
  text-align: justify;
}
.name {
  text-decoration: underline;
  font-weight: bold;
}
.logo{
  display: flex;
  justify-content: center;
  padding: 0px;
}
.logo >img{
  width: 8rem;
  height: 8rem;
}

@media (max-width: 400px) {
  .description{
    padding-left: 2%;
    padding-right: 2%;
  }
  .heading{
    font-size: 2rem;
  }
  .logo >img{
    width: 5rem;
    height: 5rem;
  }
}